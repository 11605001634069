import { Injectable } from '@angular/core';
import { TimeFormat } from './time-format.model';
import { WorkingHours, WorkingTime } from '../model/working-hours';

@Injectable({
    providedIn: 'root'
})
export class TimeFormatService {
    /**
     * Converts the working time according to the timezone.
     * @param {WorkingHours} workingHours - The working hours.
     * @param {string} timezone - The timezone.
     * @returns {WorkingHours} - The converted working hours.
     */
    convertWrokingTimeAccordingTimeZone(
        workingHours: WorkingHours,
        timezone: string
    ): WorkingHours {
        const timeFormat = new Intl.DateTimeFormat(undefined, {
            timeZone: timezone,
            timeZoneName: 'shortOffset'
        });

        const customerTimeFormat = new Intl.DateTimeFormat(undefined, {
            timeZoneName: 'shortOffset'
        });

        const date = new Date();
        const timeOffset = timeFormat.format(date).split(' ').pop() || '';
        const customerOffset =
            customerTimeFormat.format(date).split(' ').pop() || '';

        const timeHour = parseInt(
            timeOffset.replace('GMT+', '').replace('GMT', '')
        );
        const customerHour = parseInt(
            customerOffset.replace('GMT+', '').replace('GMT', '')
        );
        const difference = this.calculateTimezoneHourDifference(
            timeHour,
            customerHour
        );

        return this.getConvertedWorkingHours(workingHours, difference);
    }

    /**
     * Gets the time format information.
     * @param {string | null} timeFormat - The time format.
     * @returns {TimeFormat} - The time format information.
     */
    getTimeFormatInfo(timeFormat: string | null): TimeFormat {
        const formattedTime = new Intl.DateTimeFormat(undefined, {
            hour: 'numeric',
            minute: 'numeric'
        }).resolvedOptions();

        const hour12 = this.getIsHour12(
            formattedTime.hour12 ?? true,
            timeFormat
        );
        const hour = this.getHour(formattedTime.hour12 ?? true, timeFormat);
        const minute = '2-digit';

        return {
            hour12: hour12,
            hour: hour,
            minute: minute,
            format: timeFormat ? timeFormat : hour12 ? 'h:mm a' : 'HH:mm'
        } as TimeFormat;
    }

    /**
     * Gets the is hour 12.
     *
     * @param {boolean} systemIsHour12 - The system is hour 12 (first time login).
     * @param {string | null} timeFormat - Information about the time format (database info).
     * @returns {boolean} - The time format.
     */
    private getIsHour12(
        systemIsHour12: boolean,
        timeFormat: string | null
    ): boolean {
        return timeFormat ? timeFormat.includes('a') : systemIsHour12;
    }

    /**
     * Gets the hour.
     *
     * @param {boolean} systemIsHour12 - The system is hour 12 (first time login).
     * @param {string | null} timeFormat - Information about the time format (database info).
     * @returns {string} - The hour.
     */
    private getHour(
        systemIsHour12: boolean,
        timeFormat: string | null
    ): string {
        if (timeFormat) {
            return timeFormat.includes('a') ? 'numeric' : '2-digit';
        } else if (systemIsHour12) {
            systemIsHour12 ? 'numeric' : '2-digit';
        }
        return 'numeric';
    }

    /**
     * Calculates the timezone hour difference.
     * @param {number} timeHour - The time hour.
     * @param {number} customerHour - The customer hour.
     * @returns {number} - The timezone hour difference.
     */
    private calculateTimezoneHourDifference(
        timeHour: number,
        customerHour: number
    ): number {
        // Both can be positive or negative (e.g., GMT+2 or GMT-5)
        const difference = timeHour - customerHour;

        // Handle cases where the difference crosses over GMT
        if (Math.abs(difference) > 12) {
            // If difference is greater than 12, we need to adjust to find shorter path
            if (difference > 0) {
                return difference - 24;
            } else {
                return difference + 24;
            }
        }

        return difference;
    }

    /**
     * Gets the converted working hours.
     * @param {WorkingHours} workingHours - The working hours.
     * @param {number} difference - The difference.
     * @returns {WorkingHours} - The converted working hours.
     */
    private getConvertedWorkingHours(
        workingHours: WorkingHours,
        hoursDifference: number
    ): WorkingHours {
        const convertedWorkingHours: WorkingHours = {
            openingTimes: {}
        };

        Object.entries(workingHours.openingTimes).forEach(
            ([day, openingTime]) => {
                const convertedFrom: WorkingTime = {
                    hours: this.adjustHours(
                        openingTime.from.hours,
                        hoursDifference
                    ),
                    minutes: openingTime.from.minutes
                };

                const convertedTo: WorkingTime = {
                    hours: this.adjustHours(
                        openingTime.to.hours,
                        hoursDifference
                    ),
                    minutes: openingTime.to.minutes
                };

                convertedWorkingHours.openingTimes[day] = {
                    from: convertedFrom,
                    to: convertedTo
                };
            }
        );

        return convertedWorkingHours;
    }

    /**
     * Adjusts the hours.
     * @param {number} hours - The hours.
     * @param {number} hoursDifference - The hours difference.
     * @returns {number} - The adjusted hours.
     */
    private adjustHours(hours: number, hoursDifference: number): number {
        if (hoursDifference === 0) {
            return hours;
        } else if (hoursDifference < 0) {
            const adjustedHours = hours + Math.abs(hoursDifference);

            return adjustedHours >= 24 ? adjustedHours - 24 : adjustedHours;
        } else {
            const adjustedHours = hours - hoursDifference;

            return adjustedHours < 0 ? adjustedHours + 24 : adjustedHours;
        }
    }
}
