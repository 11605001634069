import {
    Component,
    Inject,
    OnInit,
    PLATFORM_ID,
    Renderer2,
    ViewChild
} from '@angular/core';
import { NgClass, NgStyle, isPlatformBrowser } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { NgScrollbar, NgScrollbarModule } from 'ngx-scrollbar';
import { Subject, switchMap, takeUntil } from 'rxjs';

import { AboutComponent } from './components/about/about.component';
import { PhotosComponent } from './components/photos/photos.component';
import { MainNavbarComponent } from './components/main-navbar/main-navbar.component';
import { ServicesComponent } from './components/services/services.component';
import { FooterComponent } from './components/footer/footer.component';
import { OtherLocationsComponent } from './components/other-locations/other-locations.component';
import { TeamComponent } from './components/team/team.component';
import { OpeningTimesComponent } from './components/opening-times/opening-times.component';
import { Company } from './model/company';
import { Sections } from './model/sections.enum';
import { CompanyServerService } from './company-server.service';
import { CompanyLocation } from './model/company-location';
import { User } from './model/user';
import { environment } from '../../environments/environment';
import { MediaPreviewComponent } from './components/photos/media-preview/media-preview.component';
import { Service } from './model/service';
import { FileUrlPipe } from './pipes/file-url.pipe';
import { FontTypeEnum } from './model/enum/font-type.enum';
import { TimeFormatService } from './services/time-format.service';

@Component({
    selector: 'app-company',
    standalone: true,
    imports: [
        MatSidenavModule,
        MatIconModule,
        MatButtonModule,
        HttpClientModule,
        NgScrollbarModule,
        MainNavbarComponent,
        PhotosComponent,
        AboutComponent,
        TeamComponent,
        ServicesComponent,
        OtherLocationsComponent,
        FooterComponent,
        OpeningTimesComponent,
        NgClass,
        NgStyle,
        TranslocoDirective,
        MediaPreviewComponent
    ],
    providers: [CompanyServerService, FileUrlPipe],
    templateUrl: './company.component.html',
    styleUrl: './company.component.scss'
})
export class CompanyComponent implements OnInit {
    companyInfo!: Company;
    companyLocation!: CompanyLocation;
    locationServices: Service[] = [];
    locationUniqueName = '';
    isDrawerOpened = false;
    section = Sections;
    selectedSection = Sections.PHOTOS;
    isVisible = false;
    activeIndex = 0;
    resourcesWithServices: { id: string; serviceIds: string[] }[] = [];
    transformedImageUrls: string[] = [];
    bodyFontLoaded = false;
    titleFontLoaded = false;
    @ViewChild(NgScrollbar) scrollable!: NgScrollbar;

    private componentDestroyed$ = new Subject<void>();

    constructor(
        private companyServerService: CompanyServerService,
        private route: ActivatedRoute,
        private router: Router,
        private translocoService: TranslocoService,
        private renderer: Renderer2,
        private fileUrlPipe: FileUrlPipe,
        private titleService: Title,
        private timeFormatService: TimeFormatService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {}

    ngOnInit(): void {
        this.route.params
            .pipe(
                takeUntil(this.componentDestroyed$),
                switchMap(params => {
                    this.locationUniqueName = params['locationUniqueName'];

                    return this.companyServerService
                        .getCompanyInfo(this.locationUniqueName)
                        .pipe(takeUntil(this.componentDestroyed$));
                })
            )
            .subscribe({
                next: companyInfo => {
                    this.companyInfo = companyInfo;
                    const location = this.companyInfo.locations.find(
                        l => l.uniqueName === this.locationUniqueName
                    );

                    this.titleService.setTitle(this.companyInfo.name);

                    if (location) {
                        this.companyLocation = location;

                        const workingHours =
                            this.timeFormatService.convertWrokingTimeAccordingTimeZone(
                                this.companyLocation.workingHours,
                                this.companyLocation.timezone
                            );

                        this.companyLocation = {
                            ...this.companyLocation,
                            workingHours: workingHours
                        };

                        this.handleTransformingImageUrls();
                        this.handleServices();
                        this.handleLoadingFonts();
                    }
                },
                error: () => {
                    this.router.navigate(['/']);
                }
            });
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }

    /**
     * Handles scrolling.
     * @param {Sections} section - New section.
     */
    handleScrolling(section: Sections): void {
        this.selectedSection = section;
    }

    /**
     * Navigates to the booking site.
     */
    navigate(): void {
        if (typeof window !== 'undefined') {
            window.location.href =
                environment.schedwiseHostname +
                '/public/' +
                this.companyInfo.id +
                '?preselectNavigation=true&locationId=' +
                this.companyLocation.id +
                '&lang=' +
                this.translocoService.getActiveLang();
        }
    }

    /**
     * Calls a phone number.
     * @param {string} phoneNumber - A phone number.
     */
    callNow(phoneNumber: string): void {
        window.open(`tel:+${phoneNumber}`, '_self');
    }

    /**
     * Handles services for a location.
     */
    private handleServices(): void {
        this.resourcesWithServices = this.companyLocation.resources.map(
            (resource: User) => {
                return { id: resource.id, serviceIds: resource.serviceIds };
            }
        );

        var allServiceIds = new Set(
            this.resourcesWithServices?.flatMap(x => x.serviceIds)
        );

        allServiceIds.forEach((serviceId: string) => {
            const service = this.companyInfo.services.find(
                x => x.id === serviceId && (x.hidden == null || !x.hidden)
            );

            if (service) {
                this.locationServices.push(service);
            }
        });
    }

    /**
     * Hanndles transforming image urls.
     */
    private handleTransformingImageUrls(): void {
        this.companyLocation.websiteData.imageUrls.forEach(
            (imageUrl, index) => {
                this.fileUrlPipe
                    .transform(imageUrl)
                    .subscribe(transformedImageUrl => {
                        this.transformedImageUrls.splice(
                            index,
                            0,
                            transformedImageUrl
                        );
                    });
            }
        );
    }

    /**
     * Handles loding fonts.
     */
    private handleLoadingFonts(): void {
        if (isPlatformBrowser(this.platformId)) {
            const titleFont = this.companyLocation.websiteData.titleFont;
            const bodyFont = this.companyLocation.websiteData.bodyFont;

            if (titleFont === bodyFont) {
                this.setFont(titleFont, FontTypeEnum.ALL);
            } else {
                this.setFont(titleFont, FontTypeEnum.TITLE);
                this.setFont(bodyFont, FontTypeEnum.BODY);
            }
        }
    }

    /**
     * Sets a body or/and title font.
     * @param {string} fontFamily - A font-familiy.
     * @param {FontTypeEnum} type  - A font type.
     */
    private setFont(fontFamily: string, type: FontTypeEnum): void {
        fontFamily = fontFamily.replace(' ', '');
        const fontWeights = ['400', '500', '600', '700'];
        const fontUrls = [
            `assets/fonts/${fontFamily}/static/${fontFamily}-Regular.ttf`,
            `assets/fonts/${fontFamily}/static/${fontFamily}-Medium.ttf`,
            `assets/fonts/${fontFamily}/static/${fontFamily}-SemiBold.ttf`,
            `assets/fonts/${fontFamily}/static/${fontFamily}-Bold.ttf`
        ];
        fontUrls.forEach((fontUrl, index) => {
            const fontFace = new FontFace(fontFamily, `url(${fontUrl})`, {
                weight: fontWeights[index]
            });

            fontFace.load().then(
                loadedFont => {
                    document.fonts.add(loadedFont);
                    if (index === 0) {
                        switch (type) {
                            case FontTypeEnum.TITLE:
                                this.setTitleFont(fontFamily);
                                break;
                            case FontTypeEnum.BODY:
                                this.setBodyFont(fontFamily);
                                break;
                            default:
                                this.setBodyFont(fontFamily);
                                this.setTitleFont(fontFamily);
                        }
                    }
                },
                err => {
                    console.error(err);
                }
            );
        });
    }

    /**
     * Sets a title font font family.
     * @param {string} fontFamily - A font family.
     */
    private setTitleFont(fontFamily: string): void {
        const style = this.renderer.createElement('style');
        style.textContent = `
    h1, h2, h3 {
      font-family: '${fontFamily}' !important;
    }
  `;
        this.renderer.appendChild(document.head, style);
        this.titleFontLoaded = true;
    }

    /**
     * Sets a body font font family.
     * @param {string} fontFamily - A font family.
     */
    private setBodyFont(fontFamily: string): void {
        this.renderer.setStyle(document.body, 'fontFamily', fontFamily);
        this.bodyFontLoaded = true;
    }
}
