import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {
    MatDialogRef,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgStyle } from '@angular/common';

import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { take } from 'rxjs';

@Component({
    selector: 'app-select-language-dialog',
    standalone: true,
    imports: [
        TranslocoDirective,
        MatButtonModule,
        MatDialogActions,
        MatDialogClose,
        MatDialogTitle,
        MatDialogContent,
        MatIconModule,
        NgStyle
    ],
    templateUrl: './select-language-dialog.component.html',
    styleUrl: './select-language-dialog.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class SelectLanguageDialogComponent implements OnInit {
    languageList = [
        { code: 'en', label: 'English' },
        { code: 'de', label: 'Deutsch' },
        { code: 'hr', label: 'Hrvatski' },
        { code: 'zh', label: '中文' },
        { code: 'es', label: 'Español' },
        { code: 'fr', label: 'Français' },
        { code: 'ja', label: '日本語' },
        { code: 'pt', label: 'Português' },
        { code: 'ru', label: 'Русский' },
        { code: 'vi', label: 'Tiếng Việt' },
        { code: 'cs', label: 'Čeština' }
    ];

    selectedLanguage = { code: '', label: '' };

    constructor(
        private dialogRef: MatDialogRef<SelectLanguageDialogComponent>,
        private translocoService: TranslocoService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            primaryColor: string;
        }
    ) {}

    ngOnInit(): void {
        const index = this.languageList.findIndex(
            l => l.code === this.translocoService.getActiveLang()
        );

        if (index !== -1) {
            this.selectedLanguage = this.languageList[index];
        } else {
            this.selectedLanguage = this.languageList[0];
        }
    }

    /**
     * Handles selecting a language.
     * @param {Event} event - A change event.
     */
    handleSelecting(event: Event): void {
        const index = this.languageList.findIndex(
            l => l.code === (event.target as HTMLSelectElement).value
        );

        if (index !== -1) {
            this.selectedLanguage = this.languageList[index];
        }
    }

    /**
     * Handles closing a dialog
     */
    handleClosing(): void {
        this.translocoService.setActiveLang(this.selectedLanguage.code);
        this.translocoService.langChanges$
            .pipe(take(1))
            .subscribe(() => this.dialogRef.close());
    }
}
