import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import { TimeFormatService } from '../services/time-format.service';

@Pipe({
    name: 'timeFormat',
    standalone: true
})
export class TimeFormatPipe implements PipeTransform {
    constructor(
        private timeFormatService: TimeFormatService,
        private datePipe: DatePipe
    ) {}

    transform(
        hours: number,
        minutes: string,
        timeFormat: string
    ): string | undefined {
        const timeFormatInfo =
            this.timeFormatService.getTimeFormatInfo(timeFormat);
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(parseInt(minutes));

        return this.datePipe.transform(date, timeFormatInfo.format)?.toString();
    }
}
