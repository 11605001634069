<ng-container *transloco="let t">
	@if (companyLocation.websiteData.imageUrls.length > 0) {
		<div
			class="cover-image"
			(click)="openMediaPreview(0)"
			[class.has-phone]="companyLocation.phoneNumber"
		>
			<app-image
				[imageSrc]="companyLocation.websiteData.imageUrls[0]"
				[alt]="t('photo')"
			></app-image>

			@if (companyLocation.websiteData.imageUrls.length > 1) {
				<div class="more-images">
					{{ '+ ' + (companyLocation.websiteData.imageUrls.length - 1) }}
				</div>
			}
			<div
				class="icon direction d-flex jc-center"
				(click)="$event.stopPropagation()"
			>
				<a
					class="d-flex ai-center jc-center"
					[href]="'https://www.google.com/maps?q=' + companyLocation.address"
					><mat-icon class="elumity-icon-medium"> directions</mat-icon>
				</a>
			</div>
			@if (companyLocation.phoneNumber) {
				<div
					class="icon phone d-flex jc-center"
					(click)="$event.stopPropagation()"
				>
					<a
						class="d-flex ai-center jc-center"
						[href]="'tel:+' + companyLocation.phoneNumber"
						><mat-icon class="elumity-icon-medium"> phone</mat-icon>
					</a>
				</div>
			}
			<div
				class="icon email d-flex jc-center"
				(click)="$event.stopPropagation()"
			>
				<a
					class="d-flex ai-center jc-center"
					[href]="mailTo"
					><mat-icon class="elumity-icon-medium"> email</mat-icon>
				</a>
			</div>
		</div>
	}
	<div class="layout-container photo-container d-flex gap-40 fd-column">
		<div class="company-info">
			<h1 class="company-name">
				{{ companyName + (companyLocation.name ? ' - ' + companyLocation.name : '') }}
			</h1>
			<div class="company-details ai-start d-flex">
				<div class="d-flex ai-center gap-12">
					<div class="d-flex bullet ai-center"></div>
					<span class="company-work-time">{{
						companyLocation.workingHours | workingHours: companyLocation.timeFormat | async
					}}</span>
				</div>
				<div class="company-address d-flex ai-center gap-12">
					<div class="bullet"></div>

					<a
						[ngStyle]="{
							color: companyLocation.websiteData.primaryColor
						}"
						class="text-ellipsis"
						[href]="'https://www.google.com/maps?q=' + companyLocation.address"
						target="_blank"
						>{{ companyLocation.address }}</a
					>
				</div>
				<div class="flex jc-end gap-12">
					<div
						matTooltip="{{ t('get-directions') }}"
						matTooltipPosition="above"
						class="icon d-flex jc-center"
					>
						<a
							class="d-flex ai-center jc-center"
							[href]="'https://www.google.com/maps?q=' + companyLocation.address"
							><mat-icon> directions</mat-icon>
						</a>
					</div>
					@if (companyLocation.phoneNumber) {
						<div
							matTooltip="{{ t('call-now') }}"
							matTooltipPosition="above"
							class="icon d-flex jc-center"
						>
							<a
								class="d-flex ai-center jc-center"
								[href]="'tel:+' + companyLocation.phoneNumber"
								><mat-icon> phone</mat-icon>
							</a>
						</div>
					}
					<div
						matTooltip="{{ t('share') }}"
						matTooltipPosition="above"
						class="icon d-flex jc-center"
					>
						<a
							class="d-flex ai-center jc-center"
							[href]="mailTo"
							><mat-icon> email</mat-icon>
						</a>
					</div>
				</div>
			</div>
		</div>
		@if (companyLocation.websiteData.imageUrls.length > 0) {
			<div class="image-container">
				<div
					class="image large"
					(click)="openMediaPreview(0)"
				>
					<app-image
						[imageSrc]="companyLocation.websiteData.imageUrls[0]"
						[alt]="t('photo')"
					></app-image>
				</div>
				<div
					class="image small-one"
					(click)="companyLocation.websiteData.imageUrls[1] ? openMediaPreview(1) : openMediaPreview(0)"
					[ngClass]="{ big: companyLocation.websiteData.imageUrls.length < 3 }"
				>
					<app-image
						[imageSrc]="
							companyLocation.websiteData.imageUrls[1]
								? companyLocation.websiteData.imageUrls[1]
								: companyLocation.websiteData.imageUrls[0]
						"
						[alt]="t('photo')"
					></app-image>
				</div>
				@if (companyLocation.websiteData.imageUrls.length > 2) {
					<div
						class="image small-two"
						(click)="openMediaPreview(2)"
					>
						<app-image
							[imageSrc]="companyLocation.websiteData.imageUrls[2]"
							[alt]="t('photo')"
						></app-image>
						@if (companyLocation.websiteData.imageUrls.length > 3) {
							<div class="more-images">
								{{ '+ ' + (companyLocation.websiteData.imageUrls.length - 3) }}
							</div>
						}
					</div>
				}
			</div>
		}
	</div>
</ng-container>
